import React, { useState, useEffect } from "react";
import SendIcon from "@mui/icons-material/Send";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { Tooltip } from "@mui/material";
import { Icon } from "@iconify/react";
import { useContentSetting } from "../../Hooks/ContentSetting";
import ComponentPopup from "./ComponentPopup";
import MarkDownLink from "./MarkDownLink";
import { upload_general_image_api } from "../../DAL/general_routes/general_routes";
import VoiceRecorder from "../Chatting/VoiceRecorder";
import MicIcon from "@mui/icons-material/Mic";

let max_height_iterations = 20;
let height_iterations = 0;

export default function InputEditor(props) {
  const { messageText, setMessageText, selectedConversation } = props;
  const [openFormatter, setOpenFormatter] = useState(false);
  const [openLink, setOpenLink] = useState(false);
  const [adjustHeight, setAdjustHeight] = useState(false);
  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isStartRecording, setIsStartRecording] = useState(false);
  const { userInfo, socket } = useContentSetting();

  const handleSendMessage = async ({ file, mimeType, duration }) => {
    let event = "send_message_from_class";
    let file_path = image;
    if (file) {
      const formData = new FormData();
      if (mimeType) {
        formData.append("image", file, mimeType);
      } else {
        formData.append("image", file);
      }
      const result = await upload_general_image_api(formData);
      file_path = result.image_path;
    }
    let socketData = {
      conversation_id: selectedConversation?._id,
      sender_id: userInfo._id,
      message: messageText,
    };

    if (mimeType) {
      socketData.recording_url = file_path;
      socketData.duration = duration * 1000;
    } else if (file_path) {
      socketData.images = [file_path];
    }
    socket.emit(event, socketData);
    if (openFormatter) handleToggleFormatter();
    handleRemoveFile();
  };

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    handleSendMessage({ file });
  };

  const handleGetSelectedText = (format) => {
    const inputField = document.getElementById("inputField");
    if (inputField) {
      const selectedText = inputField.value.substring(
        inputField.selectionStart,
        inputField.selectionEnd
      );
      if (selectedText) {
        var newText = inputField.value.replace(
          selectedText,
          format + selectedText + format
        );
        setMessageText(newText);
      }
    }
  };

  const handleOnBold = () => handleGetSelectedText("**");

  const handleOnItalic = () => handleGetSelectedText("*");

  const handleAddLink = (values) => {
    let make_link = ` [${values.display_text}](${values.link_address})`;
    const inputField = document.getElementById("inputField");
    const start = inputField.selectionStart;
    const end = inputField.selectionEnd;
    setMessageText(
      messageText.slice(0, start) + make_link + messageText.slice(end)
    );
    inputField.setSelectionRange(
      start + make_link.length,
      start + make_link.length
    );
    setOpenLink(false);
  };

  const handleUploadFile = (e) => {
    const _file = e.target.files[0];
    setFile(_file);
  };

  const handleRemoveFile = (e) => {
    setFile(null);
    setImage(null);
    setIsSubmitting(false);
    setIsStartRecording(false);
  };

  const handle_remove_values = () => {
    handleRemoveFile();
    setMessageText("");
  };

  const handleOpenLinkPopup = () => {
    setOpenLink(true);
  };

  const handleStartRecording = () => {
    setIsStartRecording(true);
  };

  const handleToggleFormatter = () => {
    let elements = document.getElementsByClassName("chat-formatters-box")[0];
    if (elements.offsetHeight === 0) {
      elements.style.height = `35px`;
    } else {
      elements.style.height = `0px`;
    }

    setTimeout(() => {
      setOpenFormatter(!openFormatter);
    }, 100);
  };

  const handle_card_height = () => {
    height_iterations++;
    setTimeout(() => {
      let user_info_card = document.querySelector("#selected-user-card");
      let messages_list = document.querySelector("#chat-box-body");
      let input_field = document.querySelector("#write-message-form");

      if (user_info_card && messages_list && input_field) {
        let user_info = user_info_card.offsetHeight;
        let input_height = input_field.offsetHeight;
        if (adjustHeight) {
          let height = `calc(72vh - ${user_info + input_height}px + 45px)`;
          messages_list.style.height = height;
          messages_list.style.overflow = "auto";
          setAdjustHeight(false);
        }
      } else if (height_iterations < max_height_iterations) {
        handle_card_height();
      }
    }, 300);
  };

  useEffect(() => {
    handle_card_height();
  }, [adjustHeight]);

  useEffect(() => {
    setAdjustHeight(true);
  }, [file, image, openFormatter]);

  useEffect(() => {
    handle_remove_values();
  }, [selectedConversation]);

  const recordingData = {
    setIsSubmitting,
    handleSubmit: handleSendMessage,
    isSubmitting,
    setIsStartRecording,
  };

  return (
    <>
      <form
        className="w-100 write-message"
        onSubmit={handleSubmit}
        id="write-message-form"
      >
        {isStartRecording ? (
          <VoiceRecorder {...recordingData} />
        ) : (
          <div className="input-editor-box">
            {!file && !image && (
              <>
                <input
                  color="primary"
                  accept="image/*"
                  type="file"
                  id="message-chat-upload-button"
                  style={{ display: "none" }}
                  onChange={handleUploadFile}
                />
                <label
                  htmlFor="message-chat-upload-button"
                  className="message-send-icon me-1"
                >
                  <Tooltip title="Add Image" placement="top">
                    <AddPhotoAlternateIcon />
                  </Tooltip>
                </label>
              </>
            )}
            <div className="content-editable-box">
              <div
                className={`chat-formatters-box ${
                  openFormatter ? "formatter-border" : ""
                }`}
              >
                <div className="formatter-icon" onClick={handleOnBold}>
                  <Icon fontSize="15" icon="octicon:bold-24" />
                </div>
                <div className="formatter-icon" onClick={handleOnItalic}>
                  <Icon fontSize="15" icon="fe:italic" />
                </div>
                <div className="formatter-icon" onClick={handleOpenLinkPopup}>
                  <Icon fontSize="15" icon="ion:link" />
                </div>
              </div>
              <div
                className={`message-input-box ${
                  openFormatter ? "open-formatter-input" : ""
                }`}
              >
                {(file || image) && (
                  <div className="message-image-preview-box">
                    <div className="image-preview">
                      <Tooltip title="Remove Image">
                        <span
                          className="cross-image-preview"
                          onClick={handleRemoveFile}
                        >
                          x
                        </span>
                      </Tooltip>
                      <a
                        href={file ? URL.createObjectURL(file) : image}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={file ? URL.createObjectURL(file) : image}
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                )}
                <input
                  id="inputField"
                  type="text"
                  placeholder="Write A Message"
                  value={messageText}
                  required={!(image || file)}
                  onChange={(e) => {
                    setMessageText(e.target.value);
                  }}
                  autoComplete="off"
                />
                <Icon
                  fontSize={20}
                  className="show-hide-chat-formatter"
                  icon={`${
                    openFormatter
                      ? "mingcute:down-line"
                      : "fluent:text-edit-style-16-regular"
                  }`}
                  onClick={handleToggleFormatter}
                />
              </div>
            </div>
            {messageText || file || image ? (
              <Tooltip title="Send">
                <button className="message-send-icon ms-1">
                  <SendIcon className="ms-1" />
                </button>
              </Tooltip>
            ) : (
              <Tooltip title="Record a message">
                <div
                  className="message-send-icon ms-1"
                  onClick={handleStartRecording}
                >
                  <MicIcon />
                </div>
              </Tooltip>
            )}
          </div>
        )}
      </form>
      <ComponentPopup
        openPopup={openLink}
        setOpenPopup={setOpenLink}
        title="Insert Link"
        componentToPassDown={<MarkDownLink handleAddLink={handleAddLink} />}
      />
    </>
  );
}
