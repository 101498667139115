import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import { useContentSetting } from "../../Hooks/ContentSetting";
import {
  date_to_display,
  get_short_string,
  sort_array_based_on_createdAt,
} from "../../utils/constant";
import { s3baseUrl } from "../../config/config";
import { useEffect } from "react";
import { unread_conversations_list_api } from "../../DAL/Chatting/Chatting";
import { useState } from "react";
import { useSnackbar } from "notistack";

export default function ChatPopover() {
  const { unreadMessageCount, userInfo } = useContentSetting();
  const [anchorEl, setAnchorEl] = useState(null);
  const [conversationsList, setConversationsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const get_conversations_list = async () => {
    const result = await unread_conversations_list_api();
    if (result.code === 200) {
      setConversationsList(result.conversations_list);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      get_conversations_list();
    }
  }, [open]);

  sort_array_based_on_createdAt(conversationsList);

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <div class="header-button-action" onClick={handleClick}>
          <span>{unreadMessageCount}</span>
          <i class="fa-solid fa-comment-dots"></i>
        </div>
      </Box>
      <Menu
        anchorEl={anchorEl}
        className="chat_popover_menu"
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <div className="chat-card-section">
          <div className="p-3 pt-1 chat-card-heading">
            <h6>Messages</h6>
            <span>You have {unreadMessageCount} unread messages</span>
          </div>
          <Divider />
          <div className="chatting-users-list">
            {conversationsList.map((item, index) => {
              let conversations_user = item.members.find(
                (user) => user.user_type === "student"
              );

              let unread_message_count = item.members.find(
                (user) => user._id === userInfo._id
              ).unread_message_count;

              return (
                <>
                  <div className="d-flex justify-content-between chatting-user p-2 px-3">
                    <div className="d-flex">
                      <Avatar
                        sx={{ width: 45, height: 45 }}
                        src={s3baseUrl + conversations_user.profile_image}
                      >
                        {conversations_user.name[0]}
                      </Avatar>
                      <div className="user-name">
                        <p>{conversations_user.name}</p>
                        {item.last_message && (
                          <p>
                            {get_short_string(item.last_message?.message, 25)}
                          </p>
                        )}
                      </div>
                    </div>
                    {item.last_message && (
                      <div>
                        <div className="message-date">
                          <span>
                            {date_to_display(item.last_message?.createdAt)}
                          </span>
                        </div>
                        {unread_message_count > 0 && (
                          <div className="text-end">
                            <span className="unread_message_count">
                              {unread_message_count}
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </Menu>
    </>
  );
}
