import moment from "moment";

export const holy_quran_indexes = [
  {
    part: 1,
    min: 1,
    max: 20,
  },
  {
    part: 2,
    min: 21,
    max: 38,
  },
  {
    part: 3,
    min: 39,
    max: 56,
  },
  {
    part: 4,
    min: 57,
    max: 74,
  },
  {
    part: 5,
    min: 75,
    max: 92,
  },
  {
    part: 6,
    min: 93,
    max: 110,
  },
  {
    part: 7,
    min: 111,
    max: 128,
  },
  {
    part: 8,
    min: 129,
    max: 146,
  },
  {
    part: 9,
    min: 147,
    max: 164,
  },
  {
    part: 10,
    min: 165,
    max: 182,
  },
  {
    part: 11,
    min: 183,
    max: 200,
  },
  {
    part: 12,
    min: 201,
    max: 218,
  },
  {
    part: 13,
    min: 219,
    max: 236,
  },
  {
    part: 14,
    min: 237,
    max: 254,
  },
  {
    part: 15,
    min: 255,
    max: 272,
  },
  {
    part: 16,
    min: 273,
    max: 290,
  },
  {
    part: 17,
    min: 291,
    max: 308,
  },
  {
    part: 18,
    min: 309,
    max: 326,
  },
  {
    part: 19,
    min: 327,
    max: 344,
  },
  {
    part: 20,
    min: 345,
    max: 362,
  },
  {
    part: 21,
    min: 363,
    max: 380,
  },
  {
    part: 22,
    min: 381,
    max: 398,
  },
  {
    part: 23,
    min: 399,
    max: 416,
  },
  {
    part: 24,
    min: 417,
    max: 434,
  },
  {
    part: 25,
    min: 435,
    max: 452,
  },
  {
    part: 26,
    min: 453,
    max: 470,
  },
  {
    part: 27,
    min: 471,
    max: 488,
  },
  {
    part: 28,
    min: 489,
    max: 508,
  },
  {
    part: 29,
    min: 509,
    max: 528,
  },
  {
    part: 30,
    min: 529,
    max: 549,
  },
];

export const get_min_max_page_value = (part_number) => {
  if (part_number && part_number > 0 && part_number < 31) {
    return holy_quran_indexes.filter((page) => page.part == part_number)[0];
  } else {
    return {
      part: 0,
      min: 0,
      max: 0,
    };
  }
};

export const date_to_display = (date) => {
  const currentDate = moment();
  const givenDate = moment(date);

  // Check if the given date is today
  if (givenDate.isSame(currentDate, "day")) {
    return moment(date).format("hh:mm A"); // Show time only
  }

  // Check if the given date is within the previous week
  if (givenDate.isAfter(currentDate.clone().subtract(1, "week"))) {
    return moment(date).format("dddd"); // Show day only
  }

  return moment(date).format("YYYY-MM-DD");
};

export const date_to_display_for_messages = (date) => {
  const currentDate = moment();
  const givenDate = moment(date);

  // Check if the given date is today
  if (givenDate.isSame(currentDate, "day")) {
    return moment(date).format("hh:mm A"); // Show time only
  }

  // Check if the given date is within the previous week
  if (givenDate.isAfter(currentDate.clone().subtract(1, "week"))) {
    return moment(date).format("dddd [at] hh:mm A"); // Show day only
  }

  return moment(date).format("YYYY-MM-DD [at] hh:mm A");
};

export const get_short_string = (str, limit = 30) => {
  return str && str.length < limit ? str : str?.slice(0, limit) + "...";
};

export function sort_array_based_on_createdAt(conversations) {
  return conversations.sort((a, b) => {
    const createdAtA = new Date(a.last_message?.createdAt || a.createdAt);
    const createdAtB = new Date(b.last_message?.createdAt || b.createdAt);
    return createdAtB - createdAtA;
  });
}

export const formatTime = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
};

export const markDownLink = (props) => (
  <a {...props} target="_blank" rel="noopener noreferrer">
    {props.children}
  </a>
);

export const markdown_conversion = (string) => {
  if (!string) return;
  const noAsteriskString = string.replace(/\*/g, "");
  const markdownLinkPattern = /\[([^\]]+)\]\([^\)]+\)/g;
  const result = noAsteriskString.replace(
    markdownLinkPattern,
    (match, capturedText) => {
      return capturedText;
    }
  );

  return result;
};

export const MAX_RECORDING_MINUTES = 5;
